'use client';

import styles from './index.module.scss';
import cn from 'classnames';
import { Flex } from 'antd';
import FollowUsOn from '../FollowUsOn';
import { SocialMediaFragment } from '@/@types/generated/sitecore';
import DynamicComponent from '../DynamicComponentGen';
import useIsMobile from '@/hooks/useIsMobile';

interface Props {
    data: any;
    socialMediaData: Array<SocialMediaFragment>;
}

const HomePage = (props: Props) => {
    const { data, socialMediaData } = props;
    const { isMobile } = useIsMobile();

    return (
        <div className={cn(styles.homePage)}>
            <Flex vertical>
                {/* <Flex vertical gap={30} className='xmd:px-[10px] px-5 mt-10 w-full'> */}
                <DynamicComponent componentDataList={data} />
            </Flex>

            {!isMobile && <FollowUsOn socialMediaData={socialMediaData} />}
        </div>
    );
};

export default HomePage;
