import { createElement } from 'react';
import RichText from '@/components/RichText';

import { Locale } from '@/i18n/i18n.config';
import ImageContent from '../ImageContent';
import Video from '../Video';
import ImagePreview from '../ImagePreview';
import HomeSlide from '../HomeSlide';
import HighlightItem from '../HighlightItem';
import HomeVideo from '../HomeVideo';
// import AlbumBlock from '../AlbumBlock';
import AlbumCategoriesBlock from '@/app/[lang]/album/ui/AlbumPageLayout';
import VideoCategoriesBlock from '@/app/[lang]/videos/ui/VideoPageLayout';

const components: {
    [key: string]: any;
} = {
    RichText: RichText,
    LeftImageRightContent: ImageContent,
    LeftContentRightImage: ImageContent,
    PageGenericSlider: HomeSlide,
    Video,
    Image: ImagePreview,
    NewsBlock: HighlightItem,
    RaceVideoBlock: HomeVideo,
    // AlbumCategoriesBlock: AlbumBlock,
    AlbumCategoriesBlock,
    VideoCategoriesBlock,
    default: () => <></>,
};

const DynamicComponent = ({
    componentDataList,
    className,
    lang,
    ...props
}: {
    componentDataList: any[];
    className?: string;
    lang?: Locale;
    [x: string]: any
}) => {
    return componentDataList.map((data, index) => {
        if (!data) {
            return <></>;
        }
        const template = data?.content?.template?.name || 'default';
        const Component = createElement(components[template] || components.default, {
            key: index,
            content: data.content,
            className,
            lang,
            ...props
        });
        return Component;
    });
};

export default DynamicComponent;
